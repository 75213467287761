import { Button, Divider, Input, notification, Table, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons/';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import PageContainer from '../components/PageContainer';
import Theft from '../models/Theft';
import StoreContext from '../state/Store';
import TheftContext from '../state/Theft';
import TheftActions from './components/TheftActions';
import styles from './styles/TheftScreen.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from '../hooks/fetch';
import Separator from '../components/Separator';
import StoreSelector from '../components/StoreSelector';
import AuthContext from '../state/Auth';
import Role from '../models/Role';

const { Search } = Input;

const TheftScreen = () => {
	const { thefts, setThefts } = useContext(TheftContext);
	const { stores, defaultStore } = useContext(StoreContext);
	const { user } = useContext(AuthContext);

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchTerm, setSearchTerm] = useState('');

	const isAdmin = user?.roles.includes(Role.Admin);
	const isMaintainer = user?.roles.includes(Role.Maintainer);

	const getStoreIdParam = (searchParams: URLSearchParams) => {
		const storeIdParam = searchParams.get('storeId');
		return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
			? parseInt(storeIdParam)
			: null;
	};
	const storeIdParam = getStoreIdParam(searchParams);
	const [selectedStore, setSelectedStore] = useState(storeIdParam || (isAdmin ? -1 : defaultStore));

	const url = selectedStore === -1 ? '/admin/thefts' : `/admin/thefts/store/${selectedStore}`;
	const { isFetching } = useFetch<Theft[]>(
		url,
		setThefts,
		() => {
			notification.error({
				message: `Vi kunde inte hämta stölder för butiken. Vänligen försök igen.`,
				placement: 'bottomRight',
				duration: 10,
			});
		},
		{ enabled: stores.length > 0 }
	);

	useEffect(() => {
		if (selectedStore === -1 || selectedStore === undefined) {
			setSearchParams('');
			return;
		}

		setSearchParams(`storeId=${selectedStore}`);
	}, [selectedStore, setSearchParams]);

	const displayedThefts = thefts
		.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())
		.sort((a, b) => Number(a.status) - Number(b.status))
		.filter((theft) => {
			return (
				theft.userName.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0 ||
				theft.userIdentifier.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0
			);
		});

	const columns: ColumnsType<Theft> = [
		...(stores.length > 1
			? [
					{
						title: 'Butik',
						key: 'storeId',
						ellipsis: true,
						width: 150,
						render: (_: any, t: Theft) => <>{stores.find((s) => s.id === t.storeId)?.name}</>,
					},
			  ]
			: []),
		{
			title: 'Namn',
			dataIndex: 'userName',
			key: 'userName',
			ellipsis: true,
			width: 150,
		},
		{
			title: 'Identifierare',
			dataIndex: 'userIdentifier',
			key: 'userIdentifier',
			ellipsis: true,
			width: 150,
		},
		{
			title: 'Starttid',
			key: 'start',
			ellipsis: true,
			width: 150,
			render: (_, t: Theft) => <>{dayjs(t.start).format('YYYY-MM-DD HH:mm')}</>,
		},
		{
			title: 'Sluttid',
			key: 'end',
			ellipsis: true,
			width: 150,
			render: (_, t: Theft) => <>{dayjs(t.end).format('YYYY-MM-DD HH:mm')}</>,
		},
		{
			title: 'Stulna artiklar',
			key: 'quantityProducts',
			ellipsis: true,
			width: 120,
			render: (_, t: Theft) => <>{t.quantityProducts} st</>,
		},
		{
			title: 'Status',
			key: 'status',
			width: 100,
			render: (_, t) => (
				<Tag color={t.status === 1 ? 'green' : 'red'}>{t.status === 1 ? 'Arkiverad' : 'Ej arkiverad'}</Tag>
			),
		},
		{
			width: 50,
			responsive: ['sm'],
			fixed: 'right',
			render: (_, theft: Theft) => <TheftActions id={theft.id} />,
		},
		{
			responsive: ['xs'],
			width: 50,
			fixed: 'right',
			render: (_, theft: Theft) => <TheftActions id={theft.id} />,
		},
	];

	return (
		<PageContainer
			title="Stölder"
			extra={
				(isAdmin || isMaintainer) && (
					<Button onClick={() => navigate(`report?storeId=${selectedStore}`)} type="primary" icon={<PlusOutlined />}>
						Anmäl stöld
					</Button>
				)
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					size="large"
					placeholder="Sök efter namn eller identifierare"
					style={{ width: 400 }}
				/>
				{stores.length > 1 && (
					<>
						<Separator />
						<StoreSelector all={isAdmin} stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</>
				)}
			</div>
			<Divider />
			<Table
				loading={isFetching}
				rowKey={(theft) => theft.id}
				locale={{
					emptyText: 'Inga stölder',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedThefts}
			/>
		</PageContainer>
	);
};

export default TheftScreen;
