import { Button, Card, Spin, notification } from 'antd';
import PageContainer from '../../components/PageContainer';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import styles from './styles/AssortmentInformationScreen.module.scss';
import AuthContext from '../../state/Auth';
import Role from '../../models/Role';
import Setting from '../../models/Setting';
import useFetch from '../../hooks/fetch';

const AssortmentInformationScreen = () => {
	const auth = useContext(AuthContext);
	const [setting, setSetting] = useState<Setting | undefined>(undefined);
	const [initialValue, setInitialValue] = useState<string>(``);

	const navigate = useNavigate();

	const { isFetching } = useFetch<Setting>('/admin/settings/assortmentInformation', setSetting, () => {
		notification.error({
			message: 'Vi kunde inte hämta informationen. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	useEffect(() => {
		if (setting === undefined) return;
		setInitialValue(setting.value);
	}, [setting]);

	return (
		<PageContainer
			title="Information för handlare"
			extra={
				auth.user?.roles.includes(Role.Admin) ? (
					<Button onClick={() => navigate(`edit`)} type="primary" icon={<EditOutlined />}>
						Redigera information
					</Button>
				) : (
					<></>
				)
			}
		>
			<Spin spinning={isFetching} tip="Hämtar information...">
				<Card className={styles.markdownContainer}>
					<ReactMarkdown>{initialValue}</ReactMarkdown>
				</Card>
			</Spin>
		</PageContainer>
	);
};

export default AssortmentInformationScreen;
