import { Button, DatePicker, Table, Tag, Divider, notification, Input, Row, Col } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { MobileOutlined, QrcodeOutlined, QuestionCircleOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import styles from './styles/Events.module.scss';
import { RangePickerProps } from 'antd/lib/date-picker';
import PageContainer from '../components/PageContainer';
import StoreSelector from '../components/StoreSelector';
import StoreContext from '../state/Store';
import AuthContext from '../state/Auth';
import r from '../http';
import UnitContext from '../state/Unit';
import Role from '../models/Role';
import { StoreUnlockEvent, StoreUnlockOpenType } from '../models/StoreUnlockEvent';

// TODO: Date kommer inte vara samma på alla

const { RangePicker } = DatePicker;

const ResultColumn = ({ succeeded }: { succeeded: boolean }) => {
	const color = succeeded ? 'green' : 'volcano';
	const message = succeeded ? 'öppning lyckades' : 'öppning misslyckades';

	return <Tag color={color}>{message}</Tag>;
};

const columns: ColumnsType<StoreUnlockEvent> = [
	{
		title: 'Tid',
		dataIndex: 'timestamp',
		key: 'timestamp',
		width: 110,
		render: (_, event) => dayjs(event.timestamp).format('YYYY-MM-DD kk:mm'),
		sorter: (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
	},
	{
		title: 'Öppningsmetod',
		dataIndex: 'openType',
		key: 'openType',
		width: 100,
		render: (_, event) => {
			switch (event.openType) {
				case StoreUnlockOpenType.App:
					return (
						<div>
							<MobileOutlined /> App
						</div>
					);
				case StoreUnlockOpenType.Keycard:
					return (
						<div>
							<TagOutlined /> Tagg
						</div>
					);
				case StoreUnlockOpenType.QrCode:
					return (
						<div>
							<QrcodeOutlined /> QR-Kod
						</div>
					);
				default:
					return (
						<div>
							<QuestionCircleOutlined /> Okänd
						</div>
					);
			}
		},
	},
	{
		title: 'Namn',
		dataIndex: 'name',
		key: 'name',
		width: 170,
		render: (_, event) => formatName(event.name),
		sorter: (a, b) => {
			if (!a.name) return 1;
			if (!b.name) return -1;

			return a.name.localeCompare(b.name);
		},
	},
	{
		title: 'Affär',
		dataIndex: 'storeName',
		key: 'storeName',
		width: 170,
		sorter: (a, b) => a.storeName.localeCompare(b.storeName),
	},
	{
		title: 'Identifierare',
		dataIndex: 'identifier',
		key: 'identifier',
		width: 110,
	},
	{
		title: 'Resultat',
		dataIndex: 'isSuccessful',
		key: 'isSuccessful',
		width: 100,

		render: (_, event) => <ResultColumn succeeded={event.isSuccessful} />,
		sorter: (a) => (a.isSuccessful ? 1 : -1),
	},
	{
		title: 'Felmeddelande',
		dataIndex: 'errorMessage',
		width: 120,
		key: 'errorMessage',
		render: (_, event) => {
			if (!event.errorMessage) return null;
			return getDisplayError(event.errorMessage, event.distanceMeters);
		},
	},
];

function formatName(name?: string): string {
	if (!name) return '-';

	// Remove unnecessary space
	name = name.replace(/\s+/g, ' ').trim().toLowerCase();

	return name
		.split(' ')
		.map((part) => part.substring(0, 1).toUpperCase() + part.substring(1))
		.join(' ');
}

function getDisplayError(errorString: string, distance: number | null) {
	if (!errorString) return '';

	let error: any;
	try {
		error = JSON.parse(errorString);
	} catch (e) {
		return '';
	}

	if (!error) return '';

	if (error.code) {
		try {
			switch (error.code) {
				case 'TOO_FAR_AWAY':
					return `För långt borta${distance ? `, ${Math.round(distance)}m` : ''}`;
				case 'UNABLE_TO_OPEN_LOCK':
					return error.ttlock.errmsg;
				case 'UNKNOWN':
				default:
					return '';
			}
		} catch (e) {
			console.error(e);
			return '';
		}
	}
	if (error.errmsg && error.errcode !== 0) {
		return error.errmsg;
	}

	if (error.telcred && error.telcred.info) {
		return error.telcred.info;
	}

	return '';
}

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
	return current && current > dayjs().add(1, 'day').endOf('day');
};

const EventsScreen = () => {
	const { stores, defaultStore } = useContext(StoreContext);
	const { user } = useContext(AuthContext);

	const [isLoading, setIsLoading] = useState(false);
	const [userIdSearchTerm, setUserIdSearchTerm] = useState('');
	const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().startOf('day'));
	const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().add(1, 'day').startOf('day'));
	const [events, setEvents] = useState<StoreUnlockEvent[]>([]);

	const isAdmin = user?.roles.includes(Role.Admin);
	const [selectedStore, setSelectedStore] = useState(isAdmin ? -1 : defaultStore);
	const { units } = useContext(UnitContext);

	useEffect(() => {
		if (units.length === 0) return;
		fetchEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [units]);

	const fetchEvents = async () => {
		if (stores.length === 0 || units.length === 0) return;
		const store = stores.find((store) => store.id === selectedStore);
		const unit = units.find((unit) => unit.unitName === store?.name);
		const lockId = unit?.lockId;

		if (!lockId && !isAdmin) return;
		if (!startDate || !endDate) return;

		setIsLoading(true);

		const fromUnix = startDate.unix();
		const toUnix = endDate.unix();

		let url = `/admin/store-unlock-events?fromUnix=${fromUnix}&toUnix=${toUnix}`;
		if (userIdSearchTerm) {
			url += `&identifier=${userIdSearchTerm}`;
		}
		if (lockId) {
			url += `&lockId=${lockId}`;
		}

		try {
			const response = await r.get<StoreUnlockEvent[]>(url);

			setEvents(response.data.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
		} catch (e) {
			notification.error({
				message: 'Vi kunde inte hämta händelser. Vänligen försök igen.',
				placement: 'bottomRight',
				duration: 10,
			});
		}

		setIsLoading(false);
	};

	const onDateChange: RangePickerProps['onChange'] = (dates) => {
		const [start, end] = dates ?? [null, null];
		setStartDate(start);
		setEndDate(end);
	};

	return (
		<PageContainer title="Händelser">
			<Row gutter={[10, 10]}>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
					<Input
						value={userIdSearchTerm}
						onChange={({ target }) => setUserIdSearchTerm(target.value)}
						size="large"
						placeholder="Personnummer"
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
					<RangePicker
						className={styles.datePicker}
						onCalendarChange={onDateChange}
						value={[startDate, endDate]}
						disabledDate={disabledDate}
						size="large"
					/>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 5 }}>
					{stores.length > 1 && (
						<StoreSelector
							className={styles.storeSelector}
							all={isAdmin}
							stores={stores}
							selectedStore={selectedStore}
							onChange={setSelectedStore}
							width="100%"
						/>
					)}
				</Col>
				{stores.length > 0 ? (
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 5 }}>
						<Button
							className={styles.search}
							onClick={fetchEvents}
							size="large"
							type="primary"
							icon={<SearchOutlined />}
						>
							Sök
						</Button>
					</Col>
				) : (
					<></>
				)}
			</Row>
			<Divider />
			<Table
				loading={isLoading}
				locale={{
					emptyText: 'Inga händelser',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={events}
				rowKey={(event) => event.identifier + event.timestamp}
			/>
		</PageContainer>
	);
};

export default EventsScreen;
