import Background from '../../assets/images/price-label-background.svg';
import { format2 } from '../../helpers/currency';

const color = {
  primary: '#DB421A',
  secondary: '#262829',
};

const styleContent = `
.price-label {
  height: 489px;
  width: 619px;
  position: relative;
}

.price-label__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.price-label__price {
  font-family: Glasgow-ExtraBold;
  font-size: 145px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
}

.price-label__price-amount {
  font-family: Glasgow-ExtraBold;
  font-size: 145px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  line-height: 76%;
}

.price-label__price-amount::after {
  content: "kr";
  font-family: Glasgow-DemiBold;
  position: absolute;
  font-size: 40px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  vertical-align: top;
}

.price-label__price-for-container {
  font-family: Glasgow-ExtraBold;
  font-size: 145px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  display: inline;
}

.price-label__info {
  font-family: Glasgow-DemiBold;
  font-size: 50px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  text-align: end;
}

/* --- PieceForPiece --- */

.price-label-for {
  display: flex;
}

.price-label-for__value {
  font-family: Glasgow-ExtraBold;
  font-size: 200px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  line-height: 100%;
}

.price-label-for__for-placeholder {
  position: relative;
  width: 116px;
  margin-left: -19px;
}

.price-label-for__for {
  font-size: 126px;
  font-family: Glasgow-DemiBold;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  position: absolute;
  writing-mode: sideways-lr;
  bottom: 17px;
}

.price-label-for__extra {
  font-family: Glasgow-DemiBold;
  font-size: 50px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  text-align: end;
  margin-top: -15px;
}

/* --- AmountForPrice --- */

.price-label__amount {
  font-family: Glasgow-ExtraBold;
  font-size: 76px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  margin-bottom: 5px;
  line-height: 100%;
}

.price-label__save {
  font-family: Glasgow-DemiBold;
  font-size: 32px;
  line-height: 100%;
  color: ${color.primary};
  margin: 0px;
  margin-top: 10px;
  text-align: end;
}
`;

const Style = () => <style>{styleContent}</style>;

export enum PriceType {
  Price = 'price',
  PieceForPiece = 'price-for-piece',
  AmountForPrice = 'amount-for-price',
}

export interface PriceLabel {
  type: `${PriceType.Price}`;
  price: number;
  unit: string;
  showPriceDecimals: boolean;
}

export interface PieceForPieceLabel {
  type: `${PriceType.PieceForPiece}`;
  first: number;
  second: number;
  singlePrice: string;
}

export interface AmountForPriceLabel {
  type: `${PriceType.AmountForPrice}`;
  quantity: number;
  price: number;
  save?: number;
  showPriceDecimals: boolean;
  showSaveDecimals: boolean;
}

export type Label = PriceLabel | PieceForPieceLabel | AmountForPriceLabel;

type Props = Label;

const getDisplayPrice = (price: number, showDecimals: boolean) => {
  if (Number.isInteger(price) && !showDecimals) {
    return price;
  }

  return format2(price);
};

const PriceLabel = (props: Props) => {
  if (props.type === PriceType.Price) {
    return (
      <>
        <Style />
        <div className="price-label">
          <img src={Background} style={{ width: '100%', height: '100%' }} />
          <div className="price-label__content">
            <h1 className="price-label__price">{getDisplayPrice(props.price, props.showPriceDecimals)}</h1>
            <h1 className="price-label__info">{props.unit}</h1>
          </div>
        </div>
      </>
    );
  }

  if (props.type === PriceType.PieceForPiece) {
    return (
      <>
        <Style />
        <div className="price-label">
          <img src={Background} style={{ width: '100%', height: '100%' }} />
          <div className="price-label__content">
            <div className="price-label-for">
              <h1 className="price-label-for__value">{props.first}</h1>
              <div className="price-label-for__for-placeholder">
                <h1 className="price-label-for__for">för</h1>
              </div>
              <h1 className="price-label-for__value">{props.second}</h1>
            </div>
            <h1 className="price-label-for__extra">{props.singlePrice}</h1>
          </div>
        </div>
      </>
    );
  }

  if (props.type === PriceType.AmountForPrice) {
    return (
      <>
        <Style />
        <div className="price-label">
          <img src={Background} style={{ width: '100%', height: '100%' }} />
          <div className="price-label__content">
            <h1 className="price-label__amount">{props.quantity}för</h1>
            <h1 className="price-label__price-amount">{getDisplayPrice(props.price, props.showPriceDecimals)}</h1>
            {!!props.save && (
              <h1 className="price-label__save">Spara {getDisplayPrice(props.save, props.showSaveDecimals)}kr</h1>
            )}
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default PriceLabel;
