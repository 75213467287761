import { Button, Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import StoreOfferPrintWithImagePreview from './StoreOfferPrintWithImagePreview';
import { useEffect, useId, useMemo, useState } from 'react';
import { PriceType } from '../PriceLabel';
import { OfferFormWithImage, typeSelectOptions } from '../types';
import styles from '../../styles/StoreOfferPrint.module.scss';
import { getInitialStoreOfferWithImageForm } from '../../functions/getInitialForm';
import { printJpg, printPdf } from '../../functions/print';
import ZoomController from '../ZoomController';
import FormImage from '@components/form/Image';

const storageKey = '@offer-print-A4-with-image:form:v1';

const StoreOfferPrintWithImage = () => {
	const [form] = Form.useForm<OfferFormWithImage>();
	const iFrameId = useId();
	const [zoom, setZoom] = useState<number>(1.0);

	const preTitle = Form.useWatch('preTitle', form);
	const title = Form.useWatch('title', form);
	const product = Form.useWatch('product', form);
	const extra = Form.useWatch('extra', form);
	const show24FoodLogo = Form.useWatch('show24FoodLogo', form);
	const type = Form.useWatch('type', form);
	const price = Form.useWatch('price', form);
	const priceShowDecimals = Form.useWatch('priceShowDecimals', form);
	const unit = Form.useWatch('unit', form);
	const save = Form.useWatch('save', form);
	const saveShowDecimals = Form.useWatch('saveShowDecimals', form);
	const first = Form.useWatch('first', form);
	const second = Form.useWatch('second', form);
	const singlePrice = Form.useWatch('singlePrice', form);
	const quantity = Form.useWatch('quantity', form);
	const image = Form.useWatch('image', form);
	const imageScale = Form.useWatch('imageScale', form);

	const formDisplay: OfferFormWithImage = useMemo(
		() => ({
			preTitle,
			title,
			product,
			extra,
			image,
			imageScale,
			show24FoodLogo,
			type,
			price,
			priceShowDecimals,
			unit,
			save,
			saveShowDecimals,
			first,
			second,
			quantity,
			singlePrice,
		}),
		[
			preTitle,
			title,
			product,
			extra,
			image,
			imageScale,
			show24FoodLogo,
			type,
			price,
			priceShowDecimals,
			unit,
			save,
			saveShowDecimals,
			first,
			second,
			quantity,
			singlePrice,
		]
	);

	useEffect(() => {
		localStorage.setItem(
			storageKey,
			JSON.stringify({
				...formDisplay,
				image: null,
			})
		);
	}, [formDisplay]);

	return (
		<Row gutter={20}>
			<Col span={24}>
				<Space>
					<Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printPdf(iFrameId);
						}}
						icon={<PrinterOutlined />}
					>
						Skriv ut
					</Button>
					<Button
						size="large"
						type="primary"
						onClick={async () => {
							await setZoom(1);
							printJpg('store-offer-with-image-preview');
						}}
						icon={<DownloadOutlined />}
					>
						Ladda ner bild
					</Button>
				</Space>
				<Divider />
			</Col>
			<Col span={8}>
				<iframe style={{ visibility: 'hidden', position: 'absolute' }} id={iFrameId} className="iframe" src=""></iframe>
				<Form form={form} initialValues={getInitialStoreOfferWithImageForm(storageKey)} layout="vertical" size="middle">
					<Card>
						<Form.Item label="Före rubrik" name="preTitle">
							<Input />
						</Form.Item>
						<Form.Item label="Rubrik" name="title">
							<Input />
						</Form.Item>
						<Form.Item label="Produktnamn" name="product">
							<Input />
						</Form.Item>
						<Form.Item label="Extra" help="T.ex. antal gram, jämförpris" name="extra">
							<TextArea rows={2} />
						</Form.Item>
						<FormImage label="Bild" name="image" />
						<Form.Item label="Bildskala" name="imageScale">
							<InputNumber min={0.5} step={0.05} max={2} />
						</Form.Item>
						<div className={styles.sectionDivider}>
							<Divider orientation="left" orientationMargin="0">
								Prisetikett
							</Divider>
						</div>
						<Form.Item label="Pristyp" name="type" required>
							<Select options={typeSelectOptions} />
						</Form.Item>
						{type === PriceType.Price && (
							<>
								<Form.Item label="Pris" name="price">
									<InputNumber addonAfter="Kr" precision={2} />
								</Form.Item>
								<Form.Item valuePropName="checked" name="priceShowDecimals" help="Gäller enbart vid `.00`">
									<Checkbox>Visa decimaler på pris</Checkbox>
								</Form.Item>
								<Form.Item label="Enhet" name="unit" help="T.ex. KG, förp, st">
									<Input />
								</Form.Item>
							</>
						)}
						{type === PriceType.PieceForPiece && (
							<>
								<Form.Item label="Första" name="first" required>
									<InputNumber precision={0} />
								</Form.Item>
								<Form.Item label="Andra" name="second" required>
									<InputNumber precision={0} />
								</Form.Item>
								<Form.Item label="Styckpris" name="singlePrice" help="T.ex. KG, förp, st">
									<Input />
								</Form.Item>
							</>
						)}
						{type === PriceType.AmountForPrice && (
							<>
								<Form.Item label="Antal" name="quantity" required>
									<InputNumber precision={0} />
								</Form.Item>
								<Form.Item label="Pris" name="price" required>
									<InputNumber addonAfter="Kr" precision={2} />
								</Form.Item>
								<Form.Item valuePropName="checked" name="priceShowDecimals" help="Gäller enbart vid `.00`">
									<Checkbox>Visa decimaler på pris</Checkbox>
								</Form.Item>
								<Form.Item label="Spara" name="save">
									<InputNumber addonAfter="Kr" precision={2} />
								</Form.Item>
								<Form.Item valuePropName="checked" name="saveShowDecimals" help="Gäller enbart vid `.00`">
									<Checkbox>Visa decimaler på spara</Checkbox>
								</Form.Item>
							</>
						)}
						<div className={styles.sectionDivider}>
							<Divider orientation="left" orientationMargin="0">
								Annat
							</Divider>
							<Form.Item label="Visa 24Food logo" name="show24FoodLogo" valuePropName="checked">
								<Switch />
							</Form.Item>
						</div>
					</Card>
				</Form>
			</Col>
			<Col flex="auto" className={styles.previewColumn}>
				<ZoomController zoom={zoom} setZoom={setZoom} />
				<div
					className={styles.previewContainer}
					id="store-offer-with-image-preview"
					style={{
						transform: `scale(${zoom})`,
						position: 'absolute',
						top: 80,
						left: 0,
						transformOrigin: 'center top',
					}}
				>
					<StoreOfferPrintWithImagePreview iFrameId={iFrameId} {...formDisplay} />
				</div>
			</Col>
		</Row>
	);
};

export default StoreOfferPrintWithImage;
