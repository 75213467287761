import styles from './styles/ProductScreen.module.scss';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Input, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import Product from '../models/Product';
import PageContainer from '../components/PageContainer';
import ProductActions from './components/ProductActions';
import ProductContext from '../state/Product';
import ListImage from '@components/table/ListImage';

const { Search } = Input;

const columns: ColumnsType<Product> = [
	{
		title: 'Bild',
		key: 'imageUrl',
		width: 70,
		render: (product: Product) => <ListImage src={product.imageUrl} />,
	},
	{
		title: 'Namn',
		dataIndex: 'name',
		key: 'name',
		ellipsis: true,
		width: 150,
		sorter: (a, b) => a.name.localeCompare(b.name),
	},
	{
		title: 'Artikelnummer',
		dataIndex: 'articleNumber',
		key: 'articleNumber',
		width: 150,
	},
	{
		title: 'EAN',
		dataIndex: 'ean',
		key: 'ean',
		sortDirections: ['descend', 'ascend'],
		width: 150,
	},
	{
		title: 'Inköpspris',
		key: 'inPrice',
		sortDirections: ['descend', 'ascend'],
		width: 120,
		sorter: (a, b) => a.inPrice - b.inPrice,
		render: (product: Product) => <div className={styles.tablePrice}>SEK {product.inPrice.toFixed(2)}</div>,
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, product: Product) => <ProductActions id={product.id} />,
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, product: Product) => <ProductActions id={product.id} />,
	},
];

const ProductsScreen = () => {
	const [searchTerm, setSearchterm] = useState('');
	const { products } = useContext(ProductContext);

	const navigate = useNavigate();

	const displayedProducts = products.filter((product) => {
		return (
			product.name.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0 ||
			product.ean.toLowerCase().indexOf(searchTerm) >= 0 ||
			product.articleNumber.toLowerCase().indexOf(searchTerm) >= 0
		);
	});

	return (
		<PageContainer
			title="Produkter"
			extra={
				<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
					Skapa produkt
				</Button>
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter namn, artikelnummer eller EAN"
					style={{ width: 400 }}
				/>
			</div>
			<Divider />

			<Table
				rowKey={(product) => product.id}
				locale={{
					emptyText: 'Inga produkter',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedProducts}
			/>
		</PageContainer>
	);
};

export default ProductsScreen;
