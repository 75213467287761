import React, { useContext, useEffect, useMemo, useState } from 'react';
import { equalTo, onValue, orderByChild, query, ref } from 'firebase/database';
import type { AccountApplication, AccountApplicationResponse } from '../models/AccountApplication';
import { database } from '../firebase';
import { requestPermission, sendNotification } from '../helpers/notification';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import Role from '../models/Role';
import AuthContext from './Auth';
import { playNotificationSound } from '../functions/playNotificationSound';
import FirebaseAuthContext, { AuthStatus as FirebaseAuthStatus } from './FirebaseAuth';

interface State {
	accountApplications: AccountApplication[];
}

const initialState: State = {
	accountApplications: [],
};

const AccountApplicationContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const AccountApplicationContextProvider = ({ children }: Props) => {
	const [accountApplications, setAccountApplications] = useState<AccountApplication[]>([]);
	const { user } = useContext(AuthContext);
	const { status: firebaseAuthStatus } = useContext(FirebaseAuthContext);
	const navigate = useNavigate();

	const notifyUser = async () => {
		playNotificationSound();

		const browserNotification = sendNotification('Ny kontoansökan!');
		if (browserNotification) {
			browserNotification.onclick = () => {
				browserNotification.close();
				navigate('applications');
			};

			return;
		}

		const id = Date.now().toString();
		notification.info({
			key: id,
			message: `Ny kontoansökan!`,
			description: '',
			placement: 'topRight',
			onClick: () => {
				navigate('applications');
				notification.destroy(id);
			},
		});
	};

	useEffect(() => {
		if ([FirebaseAuthStatus.Pending, FirebaseAuthStatus.SignedOut].includes(firebaseAuthStatus)) return;

		const allowedRoles = [Role.Admin, Role.Maintainer];
		const hasAccess = user?.roles.some((role) => allowedRoles.includes(role));

		if (!hasAccess) return;

		const applicationsQuery = query(ref(database, 'applications'), orderByChild('status'), equalTo('pending'));

		const unsubscribe = onValue(applicationsQuery, (snapshot) => {
			const data = snapshot.val() as Record<string, AccountApplicationResponse>;

			if (!data) {
				setAccountApplications([]);
				return;
			}

			const newApplications: AccountApplication[] = Object.keys(data).map((key) => ({
				...data[key],
				userId: key,
			}));

			if (newApplications.length > accountApplications.length) {
				notifyUser();
			}

			setAccountApplications(newApplications);
		});

		return unsubscribe;
	}, [user, firebaseAuthStatus]);

	useEffect(() => {
		requestPermission();
	}, []);

	const value: State = useMemo(() => {
		return {
			accountApplications,
		};
	}, [accountApplications]);

	return <AccountApplicationContext.Provider value={value}>{children}</AccountApplicationContext.Provider>;
};

export default AccountApplicationContext;
