import React, { useEffect, useState } from 'react';
import r from '../http';
import { ScienerLock } from '@models/ScienerLock';

interface State {
	locks: ScienerLock[];
}

const initialState: State = {
	locks: [],
};

const ScienerLockContext = React.createContext<State>(initialState);

interface Props {
	children: React.ReactNode;
}

export const ScienerLockContextProvider = ({ children }: Props) => {
	const [locks, setLocks] = useState<ScienerLock[]>([]);

	useEffect(() => {
		(async () => {
			const response = await r.get<ScienerLock[]>('/admin/sciener/locks');
			setLocks(response.data);
		})();
	}, []);

	const value: State = {
		locks,
	};

	return <ScienerLockContext.Provider value={value}>{children}</ScienerLockContext.Provider>;
};

export default ScienerLockContext;
