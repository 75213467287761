import { Button, Form, Card, Space, Divider } from 'antd';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import styles from './styles/CreateBannedAccountScreen.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import useBannedUsers from '@hooks/banned-users';
import AuthContext from '../../state/Auth';
import Role from '../../models/Role';
import UserIdentifierField from '../../gui/UserIdentifierField';
import { BannedAccountForm } from '../../models/form/BannedAccountForm';

const CreateBannedAccountScreen = () => {
	const { user } = useContext(AuthContext);
	const [form] = Form.useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const isPhoneNumber = Form.useWatch('isPhoneNumber', form);
	const { addBannedUser } = useBannedUsers();
	const navigate = useNavigate();

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 14 },
	};

	// If the user is a franchiseAdmin, hide the back button.
	// FranchiseAdmins can only add new banned users and nothing else.
	const showBackButton = user?.roles.includes(Role.Admin);

	const onSubmit = async (formData: BannedAccountForm) => {
		setIsSubmitting(true);
		const userIdentifier = !formData.isPhoneNumber ? formData.personalIdentityNumber : formData.phoneNumber;

		addBannedUser({ userIdentifier, reason: formData.reason }).then((result) => {
			if (result === true) navigate(-1);
		});

		setIsSubmitting(false);
	};

	const onChangeIsPhoneNumber = (value: boolean) => {
		form.setFieldValue('isPhoneNumber', value);
	};

	return (
		<PageContainer title="Spärra konto" back={showBackButton}>
			<Form
				form={form}
				labelAlign="left"
				{...layout}
				onFinish={onSubmit}
				size="large"
				initialValues={{ isPhoneNumber: false }}
			>
				<Card className={styles.inputs}>
					<UserIdentifierField isPhoneNumber={isPhoneNumber} onChangeIsPhoneNumber={onChangeIsPhoneNumber} />
					<Divider />
					<Form.Item<BannedAccountForm> label="Anledning" name="reason">
						<TextArea size="large" style={{ height: 120 }} />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Space size="middle">
						<Button onClick={() => navigate(-1)} type="text">
							Avbryt
						</Button>
						<Button loading={isSubmitting} type="primary" htmlType="submit" icon={<PlusOutlined />}>
							Spärra konto
						</Button>
					</Space>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateBannedAccountScreen;
