import { UploadFile } from 'antd';
import { PriceType } from './PriceLabel';

export interface OfferForm {
	preTitle: string;
	title: string;
	product: string;
	extra: string;
	show24FoodLogo: boolean;
	// Pris
	type: PriceType;
	price: number;
	priceShowDecimals: boolean;
	unit: string;
	save: number;
	saveShowDecimals: boolean;
	first: number;
	second: number;
	quantity: number;
	singlePrice: string;
}

export interface OfferFormWithImage {
	preTitle: string;
	title: string;
	product: string;
	extra: string;
	image: UploadFile[] | null;
	imageScale: number;
	show24FoodLogo: boolean;
	// Pris
	type: PriceType;
	price: number;
	priceShowDecimals: boolean;
	unit: string;
	save: number;
	saveShowDecimals: boolean;
	first: number;
	second: number;
	quantity: number;
	singlePrice: string;
}

export interface Offer4inA4WithImage {
	id: number;
	title: string;
	product: string;
	extra: string;
	image: UploadFile[] | null;
	imageScale: number;

	// Pris
	type: PriceType;
	price: number;
	priceShowDecimals: boolean;
	unit: string;
	save: number;
	saveShowDecimals: boolean;
	first: number;
	second: number;
	quantity: number;
	singlePrice: string;
}

export type Offer4inA4 = Omit<Offer4inA4WithImage, 'image'>;

export interface OfferInstagram {
	id: number;
	product: string;
	extra: string;
	image: UploadFile[] | null;
	imageScale: number;

	// Pris
	type: PriceType;
	price: number;
	priceShowDecimals: boolean;
	unit: string;
	save: number;
	saveShowDecimals: boolean;
	first: number;
	second: number;
	quantity: number;
	singlePrice: string;
	active: boolean;
}

export const typeSelectOptions = [
	{
		value: PriceType.Price,
		label: 'Enbart pris',
	},
	{
		value: PriceType.PieceForPiece,
		label: '3 För 2',
	},
	{
		value: PriceType.AmountForPrice,
		label: '3 För 100',
	},
];
